import React, { ReactNode, useEffect } from 'react'

import styled from 'styled-components'
import Div100vh from 'react-div-100vh'

import Header from './Header'
import { BaseText, H1, H2, H3, H4, A } from './Text'
import { mq } from '../utils/presets'

// Hide address bar on devices like the iPhone
// http://menacingcloud.com/?c=iPhoneAddressBar
//---------------------------------------------
function hideAddressBar() {
    let bodyTag
    // Big screen. Fixed chrome likely.
    if (screen.width > 980 || screen.height > 980) return

    // Standalone (full screen webapp) mode
    if (window.navigator.standalone === true) return

    // Page zoom or vertical scrollbars
    if (window.innerWidth !== document.documentElement.clientWidth) {
        // Sometimes one pixel too much. Compensate.
        if (window.innerWidth - 1 !== document.documentElement.clientWidth)
            return
    }

    const timer = setTimeout(function () {
        // Already scrolled?
        if (window.pageYOffset !== 0) return

        // Perform autoscroll
        window.scrollTo(0, 100)

        // Reset body height and scroll
        if (bodyTag !== undefined)
            bodyTag.style.height = window.innerHeight + 'px'
        window.scrollTo(0, 0)
    }, 1000)
    return timer
}

// Quick address bar hide on devices like the iPhone
//---------------------------------------------------
function quickHideAddressBar() {
    return setTimeout(function () {
        if (window.pageYOffset !== 0) return
        window.scrollTo(0, window.pageYOffset + 1)
    }, 1000)
}

/**
 * Scrollable contatiner - Necessary so on iOS the Address Bar/Navigation can be hidden
 */
const ScrollablePageContainer = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
`

/**
 * 100vh unfortunately does not work on iOS, where the address bar is dynamically added/removed
 * Thus have to use window.innerHeight
 */
const FullPageContainer = styled(Div100vh)`
    display: flex;
    flex-direction: column;
`

const FlexHeader = styled.div`
    flex: 0 0 auto;
`

const FixedHeader = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
`

const BodyContainer = styled.div`
    margin-top: ${(props) =>
        props.shouldAddMargin ? (!props.largeHeaderHeight ? 60 : 72) : 0}px;
    ${mq.tablet} {
        margin-top: ${(props) =>
            props.shouldAddMargin ? (!props.largeHeaderHeight ? 60 : 90) : 0}px;
    }
    flex: 1; //  1 0 auto; TODO: Need to change this I guess back
    position: relative; /* need this to position inner content */
    overflow-y: auto;
`

const FooterContainer = styled.div`
    position: ${(props) => (props.fullPage ? 'relative' : 'fixed')};
    bottom: 0px; // -1px needed for the RGBA bug in CollectionToggler
    width: 100%;
    background-color: transparent;
`

type LayoutProps = {
    header?: ReactNode
    footer?: ReactNode
    children?: ReactNode
    fullPage?: Boolean
    largeHeaderHeight?: Boolean
}

const Layout = ({
    header = <Header />,
    children,
    footer,
    fullPage = false,
    largeHeaderHeight = false,
}: LayoutProps) => {
    const PageContainer = fullPage ? FullPageContainer : ScrollablePageContainer
    const HeaderContainer = fullPage ? FlexHeader : FixedHeader

    useEffect(() => {
        window.addEventListener('load', function () {
            window.scrollTo(0, 0)
        })
    }, [])

    return (
        <PageContainer>
            <HeaderContainer>{header}</HeaderContainer>
            <BodyContainer
                shouldAddMargin={!fullPage}
                largeHeaderHeight={largeHeaderHeight}
            >
                {children}
            </BodyContainer>
            <FooterContainer fullPage={fullPage}>{footer}</FooterContainer>
        </PageContainer>
    )
}

export default Layout
