import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

// import Swiper core and required components
import SwiperCore, {
    Keyboard,
    Mousewheel,
    Navigation,
    Pagination,
    Lazy,
} from 'swiper'

// Note: Using react version of swiper!
import { Swiper, SwiperSlide } from 'swiper/react'

// install Swiper components
SwiperCore.use([Navigation, Pagination, Keyboard, Mousewheel, Lazy])

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import { mq, spacing } from '../utils/presets'
import ThemeContext from '../context/ThemeContext'
import { PhysicsPropsPlugin } from 'gsap/all'

const CursorOverlayContainer = styled.div`
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
`

const CursorOverlay = styled.div`
    flex: 1;
    background-color: transparent;
    pointer-events: none;
    @media (pointer: fine) {
        pointer-events: auto;
        cursor: url('/icons/arrow-${(props) => props.direction}-${(props) =>
                props.color}.svg'),
            auto;
        z-index: 99;
        color: white;
    }
`

const SlideContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
`

const ImageWrapper = styled.div`
    flex: 0 1 100%;
    padding: 10px 10px;
    ${mq.tablet} {
        padding: 30px 10px;
    }
    ${mq.desktop} {
        padding: 60px 60px;
    }
`

type CarouselProps = {
    images: any[]
    initialIndex?: number
    onSlideChange?: CallableFunction
    className: string
}

const Carousel = ({
    images,
    initialIndex = 1,
    onSlideChange,
    className,
}: CarouselProps) => {
    const { theme } = useContext(ThemeContext)
    const [swiper, setSwiper] = useState(null)
    return (
        <>
            <Swiper
                slidesPerView={1}
                centeredSlides={true}
                spaceBetween={0}
                mousewheel
                keyboard
                onSlideChange={(swiper) => onSlideChange(swiper.activeIndex)}
                onSwiper={setSwiper}
                initialSlide={initialIndex - 1}
                className={className}
                preloadImages={false}
                grabCursor
                lazy={{
                    loadPrevNext: true,
                    loadPrevNextAmount: 1,
                    loadOnTransitionStart: false,
                }}
                loop
            >
                {images.map((i) => (
                    <SwiperSlide key={i.image.id}>
                        <SlideContainer>
                            <ImageWrapper>
                                <picture>
                                    <source
                                        type="image/webp"
                                        data-srcset={
                                            i.image.childImageSharp.fixed
                                                .srcSetWebp
                                        }
                                    />
                                    <source
                                        type="image/jpeg"
                                        data-srcset={
                                            i.image.childImageSharp.fixed.srcSet
                                        }
                                    />
                                    <img
                                        className="swiper-lazy"
                                        // If we want to hide the ugly default border... src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                        src={
                                            i.image.childImageSharp.fixed.base64
                                        }
                                        data-src={
                                            i.image.childImageSharp.fixed.src
                                        }
                                        data-srcset={
                                            i.image.childImageSharp.fixed.srcSet
                                        }
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </picture>
                            </ImageWrapper>
                        </SlideContainer>
                    </SwiperSlide>
                ))}
            </Swiper>
            <CursorOverlayContainer>
                <CursorOverlay
                    direction="left"
                    onClick={() => swiper.slidePrev()}
                    color={theme === 'dark' ? 'white' : 'black'}
                />
                <CursorOverlay
                    direction="right"
                    onClick={() => swiper.slideNext()}
                    color={theme === 'dark' ? 'white' : 'black'}
                />
            </CursorOverlayContainer>
        </>
    )
}

export default Carousel
