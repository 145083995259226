import styled from 'styled-components'
import { mq } from './utils/presets'

export const SharedContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const Divider = styled.div`
    border-top: 3px solid;
    ${mq.tablet} {
        border-top: 4px solid;
    }
`
