import React from 'react'

import { Link as GLink } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function Link({
    children,
    to,
    animationConfig = {
        fade: true,
        duration: 1,
        direction: 'up',
    },
    ...other
}) {
    const internalLink = /^\/(?!\/)/.test(to)
    if (internalLink) {
        return (
            <GLink to={to} {...other}>
                {children}
            </GLink>
        )
        /*
        return (
            <AniLink
                to={to}
                {...animationConfig}
                swipe={animationConfig.swipe || undefined}
                fade={animationConfig.fade || undefined}
                {...other}
            >
                {children}
            </AniLink>
        )
        */
    }
    return (
        <a href={to} {...other}>
            {children}
        </a>
    )
}
