import React from 'react'

import styled from 'styled-components'

import { SharedContainer } from '../styledComponents'
import Link from './Link'
import ThemeToggler from './ThemeToggler'
import Text from './Text'
import { mq, spacing } from '../utils/presets'
import Icon from './Icon'

const Container = styled(SharedContainer)`
    flex: 1;
    padding: 0px 10px;
    padding-top: 7px;
    min-height: 72px;
    ${mq.tablet} {
        min-height: 90px;
    }
    ${mq.laptop} {
        padding: 0px 60px;
        padding-top: 7px;
    }
`

const TitleContainer = styled.div`
    flex: 1;
    display: flex;
`

const MenuContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 7px;
    ${mq.tablet} {
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
`

const StyledLink = styled(Link)`
    margin-bottom: 2px;
    height: 24px;
`

const StyledPlusIcon = styled(Icon)`
    ${spacing([30, 0, 0], 'margin-left')}
    ${mq.tablet} {
        display: none;
    }
`

const StyledText = styled(Text)`
    display: none;
    ${mq.tablet} {
        display: flex;
    }
    ${mq.laptop} {
        margin-left: 5px;
    }
`

const StyledThemeToggler = styled(ThemeToggler)`
    margin-bottom: 2px;
    ${mq.tablet} {
        margin-top: 3px;
        margin-bottom: 0px;
    }
`

export default function Header() {
    return (
        <Container>
            <TitleContainer>
                <Link to="/">
                    <Text type="h2">
                        SAMUEL BRAMLEY
                        <br /> FOTOGRAFIE
                    </Text>
                </Link>
            </TitleContainer>
            <MenuContainer>
                <StyledLink to="/info">
                    <StyledPlusIcon name="plus" width={24} height={24} />
                    <StyledText type="h2">
                        INFO <br />
                    </StyledText>
                </StyledLink>
                <StyledThemeToggler />
            </MenuContainer>
        </Container>
    )
}
