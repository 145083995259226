import React, { useContext } from 'react'
import styled from 'styled-components'

import ThemeContext from '../context/ThemeContext'
import { mq } from '../utils/presets'
import Icon from './Icon'

const Button = styled.button`
    background: inherit;
    background-color: inherit;
    color: inherit;
    border: none;
    box-shadow: none;
    line-height: 0;
    appearance: none;
    outline: none;
`

const StyledIcon = styled(Icon)`
    height: 24px;
    width: 24px;
    ${mq.tablet} {
        height: 28px;
        width: 28px;
    }
`

export default function ThemeToggler(props) {
    const { theme, toggleTheme } = useContext(ThemeContext)
    return (
        <Button
            onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
            type="button"
            aria-label="Theme toggle"
            {...props}
        >
            <StyledIcon name="theme-toggler" />
        </Button>
    )
}
