import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { navigate } from '@reach/router'

import Carousel from '../components/Carousel'
import { getProject } from './__generated__/getProject'
import Icon from '../components/Icon'
import { SharedContainer } from '../styledComponents'
import Link from '../components/Link'
import Text from '../components/Text'
import { mq } from '../utils/presets'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const StyledCarousel = styled(Carousel)`
    flex: 1;
    display: flex;
    height: 100%;
`

const HeaderContainer = styled(SharedContainer)`
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid;
    min-height: 60px;
    padding: 0px 10px;
    ${mq.tablet} {
        border-bottom: 4px solid;
    }
    ${mq.laptop} {
        padding: 0px 60px;
    }
`

const HeaderOuterItem = styled.div`
    width: 60px;
    ${mq.tablet} {
        width: 90px;
    }
    display: flex;
    justify-content: ${(props) => props.justifyContent || 'flex-start'};
`

const StyledTitle = styled(Text)`
    display: None;
    ${mq.tablet} {
        display: block;
    }
`

const FooterContainer = styled(SharedContainer)`
    justify-content: space-between;
    align-items: center;
    border-top: 3px solid;
    min-height: 60px;
    padding: 0px 10px;
    ${mq.tablet} {
        border-top: 4px solid;
    }
    ${mq.laptop} {
        padding: 0px 60px;
    }
`

const StyledLink = styled(Link)`
    width: 18px;
    height: 18px;
    ${mq.tablet} {
        width: 24px;
        height: 24px;
    }
`

const StyledIcon = styled(Icon)`
    width: 18px;
    height: 18px;
    ${mq.tablet} {
        width: 24px;
        height: 24px;
    }
    margin-right: 5px;
    ${mq.laptop} {
        margin-right: 0px;
    }
`

function getValidIndex({ hash, state }, images) {
    let index = 1
    try {
        if (state && !hash) {
            const newIndex = images.findIndex(
                (i) => i.image.id === state.fromId
            )
            index = newIndex + 1
        } else if (hash) {
            const providedIndex = Number(hash && hash.replace('#', ''))
            if (
                providedIndex &&
                Number.isInteger(providedIndex) &&
                providedIndex > 0 &&
                providedIndex <= images.length
            ) {
                index = providedIndex
            } else {
                console.warn('No valid index provided!')
            }
        }
    } catch (e) {
        console.error(`Error getting valid index! ${e.message}`)
    } finally {
        return index
    }
}

type Props = {
    location: Location
    data: getProject
}

export default function StreamTemplate({
    location,
    data, // this prop will be injected by the GraphQL query below.
}: Props) {
    const { project } = data

    // Project
    const { fields, excerpt, frontmatter } = project
    const { projectTitle, images } = frontmatter
    const { path } = fields

    const index = getValidIndex(location, images)
    const [tmpIndex, setIdx] = useState(index)
    const updateHash = (idx) => {
        let _idx = idx % images.length
        // Special case since remainder is 0 in this case
        if (idx === images.length) {
            _idx = idx
        } else if (idx === 0) {
            // Special case since the idx would be 0
            _idx = images.length
        }
        navigate(`#${_idx}`)
        setIdx(_idx)
    }
    const currentImage = images[tmpIndex - 1]

    const header = (
        <HeaderContainer>
            <HeaderOuterItem>
                <Text style={{ whiteSpace: 'nowrap' }} type="tiny">
                    <sup>{tmpIndex}</sup> &frasl; <sup>{images.length}</sup>
                </Text>
            </HeaderOuterItem>
            <StyledTitle
                type="tiny"
                style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    padding: '0 10px',
                }}
                normalNumeric
            >
                {currentImage && currentImage.title}
            </StyledTitle>
            <HeaderOuterItem justifyContent="flex-end">
                <StyledLink
                    to={`/${path.split('/')[0]}`}
                    animationConfig={{
                        swipe: true,
                        direction: 'down',
                        duration: 1,
                    }}
                >
                    <StyledIcon name="close" />
                </StyledLink>
            </HeaderOuterItem>
        </HeaderContainer>
    )
    const footer = (
        <FooterContainer>
            <Link
                to={`/${path}`}
                animationConfig={{
                    swipe: true,
                    direction: 'up',
                    duration: 1,
                }}
            >
                <Text type="h3" normalNumeric>
                    {projectTitle}
                </Text>
            </Link>
            <Link
                to={`/${path}`}
                animationConfig={{
                    swipe: true,
                    direction: 'up',
                    duration: 1,
                }}
            >
                <StyledIcon name="info" />
            </Link>
        </FooterContainer>
    )

    return (
        <Layout header={header} footer={footer} fullPage>
            <SEO
                title={`${tmpIndex}/${images.length} · ${projectTitle}`}
                description={excerpt}
                image={
                    currentImage && currentImage.image.childImageSharp.fixed.src
                }
            />
            <StyledCarousel
                images={images}
                initialIndex={tmpIndex}
                onSlideChange={updateHash}
            />
        </Layout>
    )
}

export const pageQuery = graphql`
    query getProjectWithImage($id: String) {
        project: mdx(id: { eq: $id }) {
            id
            fields {
                path
            }
            excerpt(pruneLength: 160)
            frontmatter {
                projectTitle: title
                images {
                    title
                    description
                    image {
                        id
                        childImageSharp {
                            fixed(width: 1200, quality: 90) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`
