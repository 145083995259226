import React from 'react'

import CloseIcon from '../icons/close.svg'
import OverviewIcon from '../icons/overview.svg'
import ThemeTogglerIcon from '../icons/theme-toggler.svg'
import PlusIcon from '../icons/plus.svg'
import InfoIcon from '../icons/info.svg'

type Props = {
    name: 'close' | 'overview' | 'theme-toggler' | 'plus' | 'info'
    width?: Number
    height?: Number
}

export default function Icon({
    name,
    width = 24,
    height = 24,
    ...otherProps
}: Props) {
    switch (name) {
        case 'overview':
            return (
                <OverviewIcon width={width} height={height} {...otherProps} />
            )
        case 'theme-toggler':
            return (
                <ThemeTogglerIcon
                    width={width}
                    height={height}
                    {...otherProps}
                />
            )
        case 'plus':
            return <PlusIcon width={width} height={height} {...otherProps} />
        case 'info':
            return <InfoIcon width={width} height={height} {...otherProps} />
        case 'close':
        default:
            return <CloseIcon width={width} height={height} {...otherProps} />
    }
}
